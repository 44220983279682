.Menu {
    padding: 0.5rem 1rem;
    font-size: 1rem;

    @media screen and (min-width: $breakpoint-medium) {
        padding: 1rem 2rem;
        font-size: 1.5rem;
    }

    ul {
        display: flex;
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            margin-right: 1rem;
            color: #858585;
        }
    }

    a {
        text-decoration: none;

        &:hover,
        &:active,
        &:visited,
        &.active {
            color: #858585;
        }
    }
}
