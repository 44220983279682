$breakpoint-medium: 450px;
$breakpoint-large: 800px;

%font-display {
    font-family: nitti-typewriter-cameo, sans-serif; // black background
    font-weight: 700;
    font-style: normal;
}
.font-display {
    @extend %font-display;
}

%font-primary {
    font-family: nitti-typewriter-normal, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.font-primary {
    @extend %font-primary;
}

%font-underline {
    font-family: nitti-typewriter-underlined, sans-serif;
    font-weight: 500;
    font-style: normal;
}
.font-underline {
    @extend %font-underline;
}
