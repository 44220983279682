.OverviewGrid {
    .cell {
        position: relative;
    }

    .tooltip {
        @media screen and (max-width: 800px) {
            display: none;
        }
        p {
            margin: 0.25rem 0;
        }
    }

    .title {
        position: absolute;
        top: calc(50% - 0.5rem);
        font-size: 1rem;
        text-decoration: none;
        background: #ffffffdb;
        border-radius: 0.45rem;
        padding: 0 0.25rem;

        @media screen and (min-width: 800px) {
            display: none;
        }
    }
}
