.Colorsplash {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    width: 100%;
    height: 100%;

    .drop {
        opacity: 90%;
        &:nth-child(1) {
            grid-row: 1 / 8;
            grid-column: 1 / 8;
        }
        &:nth-child(2) {
            grid-row: 1 / 7;
            grid-column: 3 / 9;
        }
        &:nth-child(3) {
            grid-row: 4/9;
            grid-column: 1/7;
        }
        &:nth-child(4) {
            grid-row: 4/9;
            grid-column: 3/10;
        }
    }
}
