@import "variables";

@import "components/About/About";
@import "components/Brushstroke/Brushstroke";
@import "components/Collection/Collection";
@import "components/Colorsplash/Colorsplash";
@import "components/Home/Home";
@import "components/Menu/Menu";
@import "components/NotFound/NotFound";
@import "components/OverviewGrid/OverviewGrid";
@import "components/Paintdrop/Paintdrop";
@import "components/PhotoGrid/PhotoGrid";
@import "components/Picture/Picture";

:root {
    font-size: 20px;
}

body,
html {
    @extend %font-primary;
    font-weight: 300;
    font-size: 1.5rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1 {
    @extend %font-display;
    font-weight: 800;
    font-size: 2.25rem;
    text-align: center;
    margin-bottom: 3rem;

    @media screen and (min-width: $breakpoint-medium) {
        text-align: left;
    }
}

p {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    @extend %font-underline;
    text-decoration: none;
    color: black;

    &:active,
    &:visited,
    &.active {
        color: black;
    }
}
