.Home {
    padding: 2rem;

    h1 {
        font-size: 2rem;
    }

    @media screen and (min-width: $breakpoint-medium) {
        .overview {
            display: flex;

            .cover {
                margin-right: 1rem;
            }
        }
    }
}
