.PhotoGrid {
    .picture {
        width: calc(100% - 1rem);
        margin: 0 auto 0.5rem auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &.portrait {
            padding-bottom: 140%;
        }
        &.landscape {
            padding-bottom: 62.5%;
        }

        @media screen and (min-width: 450px) {
            margin: 0 auto 2.5vh auto;

            &.portrait {
                height: 95vh;
                padding-bottom: 0;
            }
        }
    }
}
