.About {
    padding: 2rem;
    text-align: center;

    @media screen and (min-width: $breakpoint-medium) {
        text-align: left;
    }

    .quote {
        margin: 3rem 0;
    }

    .picture {
        width: calc(100% - 1rem);
        margin: 0 auto 0.5rem auto;
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;
        padding-bottom: 140%;

        @media screen and (min-width: $breakpoint-medium) {
            width: 400px;
            margin: 0.5rem 0;
        }
    }
}
