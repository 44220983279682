.Picture {
    .cover {
        margin: 0 auto 0.5rem auto;
        background-size: contain;
        background-repeat: no-repeat;
        min-height: calc(100vw - 2rem);

        &.mobile {
            width: 100%;
            @media screen and (min-width: $breakpoint-medium) {
                display: none;
            }
        }
        &.desktop {
            background-position: center;
            width: 500px;
            min-height: 350px;
            background-position: center;

            @media screen and (max-width: $breakpoint-medium) {
                display: none;
            }
        }
    }
}
