.Collection {
    padding: 0.5rem;
    @media screen and (min-width: $breakpoint-medium) {
        padding: 2rem;
    }

    .intro {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;

        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: row;
            align-items: center;
            margin-bottom: 3rem;
        }

        .description {
            margin-top: 1rem;
            @media screen and (min-width: $breakpoint-medium) {
                padding: 0 2rem;
                margin-top: 0;
            }
        }
    }
}
